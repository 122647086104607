<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as USER_TYPES } from './store'
import { ROUTES as ACCOUNT_ROUTES } from './router'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxListMixin from '../../../components/thux-list/ThuxListMixin'

import UserTable from './UserTable'
import UserCommonFilters from './UserCommonFilters'
import UserEdit from './UserEdit.vue'

export default {
  name: 'UserList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': UserTable,
    'common-filters': UserCommonFilters,
    'component-edit': UserEdit,
    'component-detail': UserEdit
  },
  data () {
    return {
      ACCOUNT_ROUTES,
      title: this.$t('Users'),
      fieldName: 'id',
      searchFields: [
        { key: 'filter__first_name__icontains', placeholder: this.$t('First name'), type: 'text', col: 6 },
        { key: 'filter__last_name__icontains', placeholder: this.$t('Last name'), type: 'text', col: 6 },
        { key: 'filter__email__icontains', placeholder: this.$t('Email'), type: 'text', col: 6 },
        { key: 'filter__contact__ssn__icontains', placeholder: this.$t('SSN'), type: 'text', col: 6 }
      ],
      rolePerm: 'account_user_list',
      actionEnablePermission: ['account_user_enable'],
      actionDisablePermission: ['account_user_disable']
    }
  },
  computed: {
    ...mapGetters({
      ...USER_TYPES.GENERIC.account.user.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.ACCOUNT_ROUTES.USER_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return hasPerm('account_user_create')
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: USER_TYPES.GENERIC.account.user.LIST.MUTATIONS.setSelectedList,
      setSelectAll: USER_TYPES.GENERIC.account.user.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...USER_TYPES.GENERIC.account.user.LIST.ACTIONS,
      partialUpdate: USER_TYPES.GENERIC.account.user.DETAIL.ACTIONS.partialUpdate
    }),
    doAction (actionType, fieldName = 'id') {
      if ((this.selectedList.length !== 0 || this.selectAll) && actionType) {
        const idList = this.selectedList.map((instance) => instance[fieldName])
        let counter = 0
        if (actionType === 'enable' || actionType === 'disable') {
          idList.forEach((id) => {
            this.partialUpdate({ id: id, is_active: actionType !== 'disable' }).then(() => {
              if (counter === idList.length - 1) {
                this.getList()
                this.setSelectedList([])
              }
              counter += 1
            })
          })
        } else {
          this.doCustomActions(actionType, idList, counter)
        }
      }
    }
  }
}
</script>
