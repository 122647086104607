<template>
  <b-btn :disabled="user.is_active" variant="outline-primary" size="sm" :title="$t('Send activation email')"
    @click="$emit('on-send-activation-email')">
    <i :title="$t('Send activation email')" class="fas fa-envelope" />
  </b-btn>
</template>
<script>

export default {
  name: 'ButtonSendActivationEmail',
  props: { user: {} }
}
</script>
