<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as GROUP_ROLE_TYPES } from '../group-role/store'

export default {
  name: 'GroupRoleMixin',
  created () {
    this.getGroupRoleList()
  },
  computed: {
    ...mapGetters({
      groupRoleList: GROUP_ROLE_TYPES.GENERIC.account.grouprole.LIST.GETTERS.list
    }),
    groupRoleListOptions () {
      const options = []
      if (this.groupRoleList && this.groupRoleList.results && this.groupRoleList.results.length > 0) {
        this.groupRoleList.results.forEach((instance) => {
          options.push({
            id: instance.codename,
            description: instance.description
          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setGroupRoleList: GROUP_ROLE_TYPES.GENERIC.account.grouprole.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getGroupRoleList: GROUP_ROLE_TYPES.GENERIC.account.grouprole.LIST.ACTIONS.setFilters
    }),
    getGroupRoles (form) {
      const values = []
      if (form.group_roles) {
        this.groupRoleList.forEach(element => {
          if (form.group_roles.includes(element.description)) {
            values.push({
              id: element.codename,
              description: `${element.description}`
            })
          }
        })
        return values
      }
    },
    selectGroupRole (item) {
      if (!this.form.profile) {
        this.$set(this.form, 'profile', '')
        this.$set(this.form, 'profile_description', '')
      }
      this.$set(this.form, 'profile', item.id)
      this.$set(this.form, 'profile_description', item.description)
    }
  }
}
</script>
