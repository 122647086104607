<template>
  <thux-table :instance="instance"
    :instance-name="instanceName"
    :order-by-dict="orderByDict"
    :goBack="goBack"
    :list="list"
    :fieldName="fieldName"
    :fields="fields"
    :fields-column="fieldsColumn"
    :select-all="selectAll"
    :selected-list="selectedList"
    :get-local-selected-all="getLocalSelectedAll"
    :local-selected-list="localSelectedList"
    @check-all="checkAll('id')"
    @check-none="checkNone('id')"
    @set-selected-row="setSelectedRow($event, 'id')"
    :show-checks="showChecks"
    :showDetailButton="showDetailButton"
    @change-status="changeInstanceStatus"
    @sort-changed="orderTable"
    @remove-order-by="removeOrderFromTable"
    @open-detail-form="$emit('open-detail-form', $event)">
    <template slot="other-buttons-actions-bottom"
      slot-scope="row">
      <ButtonSendActivationEmail :user="row.item"
        v-has-perms="['account_user_send_activation_email']"
        @on-send-activation-email='sendActivationEmail(row.item.id)' />
      <ButtonDelete
        v-has-perms="['account_user_destroy']"
        @on-delete='_delete(row.item)'
      />
    </template>
    <template slot="is_active" slot-scope="row">
        <status-indicator :status="row.item.is_active"></status-indicator>
    </template>
  </thux-table>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as USER_TYPES } from './store'
import { hasPerm } from '@/components/PermissionHelper'

import ThuxTableMixin from '../../../components/thux-table/ThuxTableMixin'
import ThuxTableChecksMixin from '../../../components/thux-table/thux-table-checks/ThuxTableChecksMixin'

import ButtonSendActivationEmail from './ButtonSendActivationEmail'
import ButtonDelete from './ButtonDelete'
import StatusIndicator from '../../../components/StatusIndicator.vue'

export default {
  name: 'accountTable',
  mixins: [ThuxTableMixin, ThuxTableChecksMixin],
  components: {
    ButtonSendActivationEmail,
    ButtonDelete,
    StatusIndicator
  },
  data () {
    return {
      fields: [
        {
          key: 'checks',
          label: this.$t('Checks')
        },
        {
          key: 'is_active',
          label: this.$t('Status'),
          tdClass: 'text-nowrap',
          thClass: 'text-nowrap',
          sortable: true,
          custom: true,
          value: 'is_active'
        },
        {
          key: 'email',
          label: this.$t('Email'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'email'
        },
        {
          key: 'last_name',
          label: this.$t('Last name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'last_name'
        },
        {
          key: 'first_name',
          label: this.$t('First name'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'first_name'
        },
        {
          key: 'contact__id',
          label: this.$t('Code'),
          type: 'number',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap text-right',
          sortable: true,
          value: 'contact_id'
        },
        {
          key: 'contact__ssn',
          label: this.$t('SSN'),
          type: 'text',
          thClass: 'text-nowrap',
          tdClass: 'text-nowrap',
          sortable: true,
          value: 'contact_ssn'
        },
        {
          key: 'action',
          label: this.$t('Actions'),
          tdClass: 'text-right text-nowrap',
          thClass: 'text-right text-nowrap'
        }
      ],
      fieldsColumn: ['status', 'email', 'first_name', 'last_name'],
      fieldName: 'id'
    }
  },
  computed: {
    ...mapGetters({
      selectAll: USER_TYPES.GENERIC.account.user.LIST.GETTERS.selectAll,
      selectedList: USER_TYPES.GENERIC.account.user.LIST.GETTERS.selectedList
    }),
    showChecks () {
      return true
    },
    showDetailButton () {
      return hasPerm('account_user_retrieve')
    }
  },
  methods: {
    ...mapMutations({
      setList: USER_TYPES.GENERIC.account.user.LIST.MUTATIONS.setList,
      setSelectedList:
        USER_TYPES.GENERIC.account.user.LIST.MUTATIONS.setSelectedList,
      setSelectAll: USER_TYPES.GENERIC.account.user.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...USER_TYPES.GENERIC.account.user.LIST.ACTIONS,
      _delete: USER_TYPES.GENERIC.account.user.DETAIL.ACTIONS.delete
    })
  }
}
</script>
