<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as CUSTOMER_CONTACT_TYPES } from '../../organization/contact/contact/customer-contact/store'
import { vue } from '../../../main'

export default {
  name: 'CustomerContactMixin',
  props: { contact: {} },
  created () {
    this.setCustomerContactList({ filter__contacts: this.contact })
  },
  computed: {
    ...mapGetters({
      customerContactList: CUSTOMER_CONTACT_TYPES.GENERIC.organization.customercontact.LIST.GETTERS.list
    }),
    customerContactOptions () {
      const options = []
      if (this.customerContactList.results && Array.isArray(this.customerContactList.results)) {
        this.customerContactList.results.forEach((instance) => {
          let text = `${instance.last_name} ${instance.first_name}`
          if (instance.ssn) {
            text = `${text} (${instance.ssn})`
          }
          options.push({
            value: instance.id,
            last_name: text,
            c_last_name: instance.last_name,
            c_first_name: instance.first_name

          })
        })
      }
      return options
    }
  },
  methods: {
    ...mapMutations({
      setCustomerContactList: CUSTOMER_CONTACT_TYPES.GENERIC.organization.customercontact.LIST.MUTATIONS.setList
    }),
    ...mapActions({
      getCustomerContactList: CUSTOMER_CONTACT_TYPES.GENERIC.organization.customercontact.LIST.ACTIONS.setFilters
    }),
    onSearchCustomerContact (search) {
      if (search.length >= 2) {
        vue.$store.commit('componentLoading', false)
        this.getCustomerContactList({ filter__contacts: this.contact, filter__last_name__icontains: search }).then(() => {
          vue.$store.commit('componentLoading', true)
        })
      } else {
        this.setCustomerContactList({})
      }
    },
    selectCustomerContact (item) {
      this.$set(this.form, 'contact', item.value)
      this.$set(this.form, 'contact_full_info', item.last_name)
    },
    clearCustomerContact () {
      this.$set(this.form, 'contact', null)
      this.$set(this.form, 'contact_full_info', null)
    }
  }
}
</script>
