<template>
  <panel :title="title" noButton="true" v-has-perms="rolePerm">
    <div>
      <b-form>
        <!-- <b-form-row>
          <form-thux-horizontal-input
            v-if="!id"
            :validator="$v.form.id"
            :external-errors="errors['id']"
            :label-form="'Id' | translate"
            class-form="col-12 mb-2"
            type="text"
            :value="form.id"
            :disabled="!formEditable"
            @change="$set(form, 'id', $event)"
          />
        </b-form-row> -->
        <b-form-row>
          <form-thux-horizontal-autocomplete
            :validator="$v.form.contact"
             :external-errors="errors['contact']"
            :label-form="'Contact' | translate"
            class-form="col-6 mb-2 mt-2"
            :disabled="!formEditable"
            :label-search="'last_name'"
            :value="form.contact ? { value: form.contact, last_name: form.contact_full_info } : null"
            :options="customerContactOptions"
            :placeholder="form.contact ? form.contact_name : $t('Search by last name')"
            :placeholder-field="form.contact_full_info"
            @search="onSearchCustomerContact"
            @select="setInfoAndSelectCustomerContact"
            @clear="clearCustomerContact"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.last_name"
            :external-errors="errors['last_name']"
            :label-form="'Last name' | translate"
            class-form="col-6 mb-2"
            type="text"
            :value="form.last_name"
            :disabled="!formEditable"
            @change="$set(form, 'last_name', $event)"
          />
          <form-thux-horizontal-input
            :validator="$v.form.first_name"
            :external-errors="errors['first_name']"
            :label-form="'First name' | translate"
            class-form="col-6 mb-2"
            type="text"
            :value="form.first_name"
            :disabled="!formEditable"
            @change="$set(form, 'first_name', $event)"
            />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-input
            :validator="$v.form.email"
            :external-errors="errors['email']"
            :label-form="'Email' | translate"
            class-form="col-6 mb-2"
            type="text"
            :value="form.email"
            :disabled="!formEditable"
            @change="$set(form, 'email', $event)"
          />
        </b-form-row>
        <b-form-row>
          <form-thux-horizontal-select
            :validator="$v.form.group_roles"
             :external-errors="errors['group_roles']"
            :label-form="'Role' | translate"
            class-form="col-6 mb-2 mt-2"
            :disabled="!formEditable"
            :label-search="'description'"
            :value="form.profile ? { id: form.profile, description: form.profile_description } : null"
            :options="groupRoleListOptions"
            :placeholder="$t('Search or select a {instance}', { instance: $t('role') })"
            :placeholder-field="form.profile_description"
            @select="selectGroupRole"
            @clear="$set(form, 'profile', null)"
          />
        </b-form-row>
      </b-form>
      <div class="mt-3 f-w-600 d-flex justify-content-end">
        <b-button
          class="mr-3"
          variant="default"
          @click.prevent="formEditable ? cancel() : closeForm()">
          {{ formEditable ? 'Cancel' : 'Close' | translate }}
        </b-button>
        <b-button
          variant="primary"
          v-has-perms="editRolePerm"
          @click.prevent="formEditable ? onSubmit() : makeFormEditable()"
          :disabled="$v.form.$invalid">
          {{ formEditable ? 'Save' : 'Edit' | translate }}
        </b-button>
      </div>
    </div>
  </panel>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'
import { TYPES as USER_TYPES } from './store'

// import slugify from 'slugify'

import ThuxDetailPanelMixin from '../../../components/thux-detail-panel/ThuxDetailPanelMixin'
import CustomerContactMixin from './CustomerContactMixin'
import GroupRoleMixin from './GroupRoleMixin'

export default {
  name: 'accountEdit',
  mixins: [ThuxDetailPanelMixin, CustomerContactMixin, GroupRoleMixin],
  data () {
    return {
      pageName: 'Account',
      rolePerm: ['account_user_retrieve'],
      editRolePerm: ['account_user_update', 'account_user_set_user_profile', 'account_user_set_contact']
    }
  },
  computed: {
    ...mapGetters({
      ...USER_TYPES.GENERIC.account.user.DETAIL.GETTERS
    })
  },
  components: { },
  methods: {
    ...mapActions({
      ...USER_TYPES.GENERIC.account.user.DETAIL.ACTIONS
    }),
    mounted () {
      this.getGroupRoles()
    },
    setInfoAndSelectCustomerContact (item) {
      // this.$set(this.form, 'contact_full_info', item.last_name)
      if (!this.form.first_name) {
        this.$set(this.form, 'first_name', item.c_first_name)
      }
      if (!this.form.last_name) {
        this.$set(this.form, 'last_name', item.c_last_name)
      }
      this.selectCustomerContact(item)
    },
    setForm () {
      const form = Object.assign({}, this.detail)
      this.$set(this, 'form', form)
    },
    getFormData () {
      const formData = Object.assign({}, this.form)
      return formData
    },
    onSubmit () {
      const formData = this.getFormData()
      const promise = this.id ? this.update(formData) : this.create(formData)
      promise.then(
        () => {
          formData.id = this.detail.id
          this.setContact(formData).then(
            () => {
              this.setProfile(formData).then(
                () => {
                  setTimeout(() => {
                    if (!this.id) {
                      this.closeForm()
                    } else {
                      this.makeFormReadonly()
                    }
                  }, 500)
                }
              )
            }
          )
        }
      )
    }
    // ,
    // changeName (evt) {
    //   this.$set(this.form, 'name', evt)
    //   if (this.formEditable) {
    //     this.setSlug()
    //   }
    // },
    // setSlug () {
    //   this.$v.form.name.$touch()
    //   if (this.form.name) {
    //     this.$set(this.form, 'slug', slugify(this.form.name, { remove: /[*+~.()'"!:@]/g }))
    //   } else {
    //     this.$set(this.form, 'slug', undefined)
    //   }
    // }
  },
  validations: {
    form: {
      // id: { required },
      first_name: { required },
      last_name: { required },
      email: {},
      contact: {},
      groupRole: {}
    }
  }
}
</script>
