<template>
  <b-btn variant="outline-primary" size="sm" :title="$t('Delete user')"
    @click="$emit('on-delete')">
    <i :title="$t('Delete user')" class="fas fa-trash" />
  </b-btn>
</template>
<script>

export default {
  name: 'ButtonDelete'
}
</script>
